import React from 'react';

import { Box, Button, Typography, Tooltip } from '@mui/material';

import SectionContainer  from '../utils/SectionContainer';


export const Video = () => {

    return (
        <SectionContainer background={"#b19cd9"}>
            <Box sx={{
                textAlign:"center"
            }}>
                <Typography variant="h5" color={"#fff"} sx={{marginBottom:2}} >Annoucing</Typography>
                <video width="320" height="240" autoplay controls>
                    <source src="https://svajhart-pub-photos.s3.us-east-2.amazonaws.com/reveal.mp4" type="video/mp4"/>
                </video>
            </Box>
        </SectionContainer>
    )
}