

import { Box, Button, Typography, Tooltip } from '@mui/material';

import SectionContainer  from '../utils/SectionContainer';

import amazonIcon from '../../imgs/amazon.svg';
import targetIcon from '../../imgs/target.svg';
import babyListIcon from '../../imgs/babylist.svg';

export const Registry = () => {
    return (
        <SectionContainer background={"#fff"}>
          <Box sx={{textAlign:"center" , marginBottom:2}}><Typography variant="h4" sx={{fontFamily: '"Segoe UI"'}} color={"text.light"}>Registry Links</Typography></Box>
          <Box sx={{display:"flex", justifyContent: "center"}}>
            {/* <Tooltip title={"Amazon Registry"}>
              <Button sx={{marginRight:2}} onClick={()=>{window.open("https://www.amazon.com/baby-reg/alexiemichael-svajhart-september-2023-firestone/39W6DG9V88QS5", '_blank');}} variant={"contained"}><img src={amazonIcon}  height="42" width="32" alt="Amazon Registry"/></Button>
            </Tooltip> */}
            <Tooltip title={"BabyList Registry"}>
              <Button  onClick={()=>{window.open("https://www.babylist.com/list/babysvajhart", '_blank');}} variant={"contained"}><img src={babyListIcon} height="42" width="32" alt="Target Registry"/></Button>
            </Tooltip>
          </Box>
        </SectionContainer>
    )
}