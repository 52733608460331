import { Box } from "@mui/material";

const SectionContainer = ({children, background}) => {
  return(
    <Box sx={{background: background}}>
      <Box sx={{width: "100%", maxWidth: 1200, margin: "0 auto", padding: "2rem 30px"}}>
        <section>
          {children}
        </section>
      </Box>
    </Box>
    
  )
}

export default SectionContainer;