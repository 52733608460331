import React from "react";

import './App.css';

import { Box, Typography } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';


import { Polls } from "./components/polls";
import { GetUpdates } from "./components/getupdates";
import { Photos } from "./components/photos";
import { Registry } from "./components/registry";
import { Event } from "./components/event";
import { QandA } from "./components/QandA";
import { Video } from "./components/video";

import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';



function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#b19cd9',
      },
      secondary: {
        main: '#fff',
      },
      info :{
        main: '#ffffff',
      },
      text:{
        light: "#020202",
        dark: "#ffffff"
      },
      typography: {
        fontFamily: [
          'Segoe UI',
          'Roboto',
        ].join(','),
      },
      
    },
  });

  theme.typography.h1 = {
    fontSize: '3rem',
    '@media (min-width:600px)': {
      fontSize: '5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '5remrem',
    },
  };
  theme.typography.h2 = {
    fontSize: '2rem',
    '@media (min-width:600px)': {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4remrem',
    },
  };

  theme.typography.h3 = {
    fontSize: '1.75rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3remrem',
    },
  };
  theme.typography.h4 = {
    fontSize: '1.25rem',
    '@media (min-width:600px)': {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5remrem',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <main>
        <nav>
          <Box sx={{display:"flex", justifyContent:"space-between", padding: "8px", background:"#b19cd9"}}>
            <Box/>
            <Box><PregnantWomanIcon fontSize="large" color="info"/></Box>
            <Box></Box>
          </Box>
        </nav>
        <header>
          <Box sx={{position: "absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)", textAlign:"center", zIndex:1}}>
            <Box sx={{padding: "12px", border: "2px solid white", boxSizing: "border-box"}}>
              <Box sx={{padding: "20px 40px", background: "rgba(0,0,0,0.3)", border: "1px solid white", boxSizing: "border-box"}}>
                <Typography variant='h1' color={"white"} sx={{fontFamily: '"Segoe UI"'}}>Baby Svajhart</Typography>
                <Typography variant='h5' color="white" sx={{fontFamily: '"Segoe UI"'}}>Coming January 2025</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{position: "absolute", top:"95%", left:"50%", transform: "translate(-50%, -50%)", textAlign:"center", zIndex:1}}>
            <Box sx={{textAlign:"center"}}>
              <Box>
                <ChildFriendlyIcon fontSize={"large"} color={"info"}/>
              </Box>
              <Box>              
                <KeyboardArrowDownIcon fontSize={"small"} color={"info"}/>
              </Box>
            </Box>
          </Box>
        </header>
        {/* <Event/> */}
        <Video/>
        {/* <Photos/> */}
        <Registry/>
        <QandA/>
        <Polls/>
        <GetUpdates/>
      </main>
    </ThemeProvider>
  );
}

export default App;
