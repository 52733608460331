import React from "react";
import SectionContainer from "../utils/SectionContainer";

import { Box, Button, Typography, Dialog, DialogTitle } from '@mui/material';
import Slider from "react-slick";
export const QandA = () => {


    const [questions, setQuestions] = React.useState([
        {
            title: "Sweet vs Salty",
            boy:"Saltier or savory items, such as meat, cheese, and other forms of protein",
            girl: "Sweet things like chocolate, pastries, juice, and fruit.",
            answer:"Mix of both but more meats and cheeses then when I was pregnant with Penny."
        },
        {
            title: "Heartburn",
            boy:"If you don’t experience much heartburn, that’s a sign you’re having a boy.",
            girl: "Constant or more intense heartburn is a sign you’re having a girl—and that she’llbe born with a full head of hair!",
            answer:"Not very much."
        },
        {
            title: "Morning Sickness",
            boy:"If you don’t have any morning sickness or just mild nausea, it’s a boy.",
            girl: "If you’re experiencing a lot of morning sickness and feel ill, you’re having a girl.",
            answer:"Some nausea but sugnificatly better then with Penny."
        },
        {
            title: "Hair and Nails",
            boy:"If you have shiny, luscious, and healthy hair and nails, welcome to the world, baby boy!",
            girl: "If you notice your hair and nails feel brittle, dry, and thin, hello, baby girl!",
            answer:"Healthy hair and more firm nails."
        },
        {
            title: "Acne",
            boy:"No extra pimples and dry skin during pregnancy mean it’s a boy!",
            girl: "If you have oily skin while pregnant, those pimples are a sign you’re having a girl!",
            answer:"More Pimply then normal"
        },
        {
            title: "Pregnancy Brain",
            boy:"Feeling tired and a little clumsy is a sign you’re having a boy. ",
            girl: "If you’re fairly alert and steady on your feet while pregnant, hello, baby girl.",
            answer:"Alert and steady for the most part."
        },
        {
            title: "Baby Bump Position",
            boy:"If you carry lower in your abdomen, closer to your pelvic bone, it’s a little baby boy.",
            girl: "If you’re carrying high, as in your baby bump sits higher on your belly, it’s a sign you’re having a girl.",
            answer:"More low but it might be too early to tell"
        },
        {
            title: "Baby Bump Shape",
            boy:"If your bump looks narrow and pops forward, as if you have a basketball under your shirt, it’s a boy!",
            girl: "A wide baby bump is a sign you’re having a girl.",
            answer:"More wide then narrow."
        },
        {
            title: "Weight Gain",
            boy:"If most of your weight gain happens in your belly, it’s a boy.",
            girl: "If the extra pounds appear throughout your body, especially in your bottom and thighs, it’s a girl.",
            answer:"Mostly in the belly if I had to choose"
        },
        {
            title: "Partner’s Weight Gain",
            boy:"If your partner’s weight stays about the same, it’s a boy.",
            girl: "If your partner gains weight while you’re pregnant, it’s a sign you’re having a girl.",
            answer:"Generally stayed the same / lost a few lbs."
        },
        {
            title: "Baby’s Heartbeat",
            boy:"If your baby’s heartbeat is less than 140 bpm, then it’s a sign you’re having a boy.",
            girl: "If your baby’s fetal heart rate is above 140 beats per minute (bpm), you're having a girl.",
            answer:"In the initial ultrasound the heartbeat was above 140 (160BPM), in the 12 week follow up the baby was mid 150's"
        },
        {
            title: "Blood Pressure",
            boy:"If your blood pressure didn’t change before getting pregnant, it’s a baby boy.",
            girl: "If you had higher blood pressure before you became pregnant, get ready for a girl.",
            answer:"Blood Pressure is lower then normal."
        },
        {
            title: "Key Test",
            boy:"If you pick the key up by its rounded top, welcome to the world, baby boy!",
            girl: "If you pick the key up by its narrow end, it’s a girl!",
            answer:"When Michael set down the key on a table and given the choice I chose the rounded end of the key."
        },
        {
            title: "Fork or Spoon Test",
            boy:"If you chose the seat with the fork, it’s a baby boy.",
            girl: "If you choose the seat with the spoon, you’re having a girl.",
            answer:"When given the choice between two chairs I sat on the chair with a spoon benieth it."
        },
        
        // {
        //     title: "",
        //     boy:"",
        //     girl: "",
        //     answer:""
        // },
    ])

    const settings =  {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: false,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            }
          }
        ]
      };

  

    return (
        <SectionContainer background={"#b19cd9"}>
            <Box sx={{textAlign:"center", color:"#fff"}}>
                <Typography variant="h3">Old Wives Tale Gender Prediction</Typography>
            </Box>
            <Slider {...settings}>
                {
                    questions.map(q=>
                        <Slide question={q}/>
                    )
                }
                
            </Slider>
        </SectionContainer>
    )
}




const Slide = ({question}) => {
    const [open, setOpen] = React.useState(false);
   
    return (
        <Box>
            <QuestionDialog question={question} open={open} handleClose={()=>{setOpen(false)}}/>
            <Box sx={{
                textAlign:"center",
                margin:3,
                backgroundColor:"#fff",
                borderRadius:3,
                podding:4,
                cursor:"pointer"
            }}
            onClick={()=>{setOpen(true)}}>
                <Box><Typography variant="h6">{question.title}</Typography></Box>
            </Box>
        </Box>
    )
}


const QuestionDialog = ({question, open, handleClose}) => {
    return(
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{question.title}</DialogTitle>
            <Box sx={{padding:"1rem"}}>
                <Box sx={{textAlign:"center", marginBottom:"1rem"}}>
                    <Typography variant="h5" color="#699bd5" sx={{textDecoration: 'underline'}}>Boy</Typography>
                    <Typography variant="p">{question.boy}</Typography>
                </Box>
                <Box sx={{textAlign:"center", marginBottom:"1rem"}}> 
                    <Typography variant="h5" color="#F4C2C2" sx={{textDecoration: 'underline'}}>Girl</Typography>
                    <Typography variant="p">{question.girl}</Typography>
                </Box>
                <Box sx={{textAlign:"center"}}>
                    <Typography variant="h5" sx={{textDecoration: 'underline'}}>Answer</Typography>
                    <Typography variant="p">{question.answer}</Typography>
                </Box>
            </Box>
        </Dialog>
    )
}