import React, {useState, useEffect} from 'react';
import SectionContainer from '../utils/SectionContainer';
import { Box, TextField, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export const GetUpdates = () => {
    const [formData, setFormData] = useState({
      email: ""
    })
    
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  
    const post = async (e) => {
      e.preventDefault();
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(formData.email.match(validRegex)){ // use can also use e.target.reportValidity
        // submitForm
        
        console.log(formData)
        await fetch("https://y0krh5ag69.execute-api.us-east-2.amazonaws.com/api/signup", 
          {
            method: "PUT",
            body: JSON.stringify(formData)
          }   
        );
      }else{
        console.error("Invalid email")
        return;
      }
    }
    return(
        <SectionContainer background={'#b19cd9'}>
            <Box sx={{alignItems: "center"}}>
                <Box sx={{textAlign:"center" , marginBottom:2}}><Typography variant="h4" sx={{fontFamily: '"Segoe UI"'}} color={"text.dark"}>Get Updates</Typography></Box>
                <form>
                    <Box sx={{display:"flex", alignContent: "center", justifyContent:"center"}}>
                    <TextField 
                        id="email" 
                        label="Email" 
                        variant="outlined"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        sx={{marginRight:1 }}
                        color="info"
                    />
                    <IconButton onClick={post} type={"submit"} color="secondary" >
                        <SendIcon />
                    </IconButton>
                    </Box>
                </form>
            </Box>
        </SectionContainer>
    )
  }