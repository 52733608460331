import React, {useEffect, useState} from 'react'
import SectionContainer from '../utils/SectionContainer';
import { Box } from '@mui/material';

export const Polls = () => {
    return (
        <SectionContainer background={"#fff"}>
            <Box sx={{display:"flex", justifyContent: "center"}}>
                <div class="strawpoll-embed" id="strawpoll_e6Z2Aemz5gN" style={{ height: "380px", width: "100%", margin: "0 auto", display: "flex", flexDirection: "column"}}><iframe title="StrawPoll Embed" id="strawpoll_iframe_e6Z2Aemz5gN" src="https://strawpoll.com/embed/e6Z2Aemz5gN" style={{position: "static", visibility: "visible", display: "block", width: "100%", flexGrow: 1}} frameborder="0" allowfullscreen allowtransparency>Loading...</iframe><script async src="https://cdn.strawpoll.com/dist/widgets.js" charset="utf-8"></script></div>
                {/* <div class="strawpoll-embed" id="strawpoll_7MZ0kz6PKgo" style={{ height: "400px", width: "100%", margin: "0 auto", display: "flex", flexDirection: "column"}}><iframe title="StrawPoll Embed" id="strawpoll_iframe_7MZ0kz6PKgo" src="https://strawpoll.com/embed/7MZ0kz6PKgo" style={{position: "static", visibility: "visible", display: "block", width: "100%", flexGrow: 1}} frameborder="0" allowfullscreen allowtransparency>Loading...</iframe><script async src="https://cdn.strawpoll.com/dist/widgets.js" charset="utf-8"></script></div> */}
            </Box>
         </SectionContainer>
    )
};
